import React, { useEffect, useState, useRef } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../../Style/theme";
import PaymentDetails from "../Atoms/PaymentDetails";
import TermsAndConditions from "../Atoms/TermsAndConditions";
import downDropIcon from "../../../../assets/icon/downDropIcon.png";
import { useMediaQuery } from "react-responsive";
import { handleSubscriptionPayment } from "../../../../Util/bootpayUtil";
import visibleIcon from "../../../../assets/icon/visible_icon.png";
import inVisibleIcon from "../../../../assets/icon/Invisible_icon.png";
import { useParams } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
`;

const Title = styled.h2`
  ${(props) =>
    props.isDesktop
        ? css`
          font-size: ${props.theme.Web_fontSizes.Header2};
          font-weight: ${props.theme.fontWeights.Header2};
          line-height: ${props.theme.LineHeight.Header2};
          margin-top: 120px;
        `
        : css`
          font-size: ${props.theme.App_fontSizes.Header5};
          font-weight: ${props.theme.fontWeights.Header5};
          line-height: ${props.theme.AppLineHeight.Header5};
          margin-bottom: 5.3333vw;
          margin-top: 5vw;
        `}
  color: ${(props) => props.theme.colors.black01};
  text-align: center;
`;

const Body30 = styled.div`
  ${(props) =>
    props.isDesktop
        ? css`
          font-size: ${props.theme.Web_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body30};
          line-height: ${props.theme.LineHeight.Body20};
          margin-bottom: 0.1vw;
          margin-top: ${(props) => props.top || 32}px;
          width: 100%;
        `
        : css`
          font-size: ${props.theme.App_fontSizes.Body30};
          font-weight: ${props.theme.fontWeights.Body30};
          line-height: ${props.theme.AppLineHeight.Body30};
          margin-bottom: 1.0667vw;
          margin-top: ${(props) => props.top || 5.3333}vw;
          width: 85vw;
        `}
  color: ${(props) => props.theme.colors.black01};
  font-family: "Pretendard";
  text-align: left;
`;

const InputContainer = styled.div`
  position: relative;
  ${(props) =>
    props.isDesktop
        ? css`
          width: 100%;
        `
        : css`
          width: 100%;
          margin-right: 8.5333vw;
        `}
`;

const EyeIcon = styled.img`
  position: absolute;
  ${(props) =>
    props.isDesktop
        ? css`
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 32px;
          height: 32px;
        `
        : css`
          right: -4.2667vw;
          top: 50%;
          transform: translateY(-50%);
          width: 6.4vw;
          height: 5.6vw;
        `}
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  ${(props) =>
    props.isDesktop
        ? css`
          font-size: ${props.theme.Web_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.LineHeight.Header6};
          border-radius: 16px;
          border: 1px solid var(--Light-Gray00, #f2f2f8);
          background: var(--Light-White10, #f8f8fa);
          padding: 24px;
          ${props.type === "password" &&
        css`
            -webkit-text-security: disc;
          `}
          width: 94.5%;
        `
        : css`
          font-size: ${props.theme.App_fontSizes.Body10};
          font-weight: ${props.theme.fontWeights.Body10};
          line-height: ${props.theme.AppLineHeight.Body10};
          border-radius: 4.2667vw;
          border: 0.2667vw solid var(--Light-Gray00, #f2f2f8);
          background: var(--Light-White10, #f8f8fa);
          padding: 4.2667vw;
          ${props.type === "password" &&
        css`
            -webkit-text-security: disc;
          `}
        `}
`;

const Select = styled.select`
  ${(props) =>
    props.isDesktop
        ? css`
          font-size: ${props.theme.Web_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.LineHeight.Header6};
          border-radius: 16px;
          border: 0.0694vw solid var(--Light-Gray00, #f2f2f8);
          background: var(--Light-White10, #f8f8fa);
          padding: 1.1111vw;
          appearance: none;
          background-image: url(${downDropIcon});
          background-repeat: no-repeat;
          background-position: right 1.1111vw center;
          background-size: 1.1111vw;
          width: 100%;
        `
        : css`
          font-size: ${props.theme.App_fontSizes.Body10};
          font-weight: ${props.theme.fontWeights.Body10};
          line-height: ${props.theme.AppLineHeight.Body10};
          /* margin-bottom: 2.6667vw; */
          border: 0.2667vw solid var(--Light-Gray00, #f2f2f8);
          border-radius: 4.2667vw;
          background: var(--Light-White10, #f8f8fa);
          padding: 4.2667vw;
          appearance: none;
          background-image: url(${downDropIcon});
          background-repeat: no-repeat;
          background-position: right 4.2667vw center;
          background-size: 4.2667vw;
          width: 111%;
        `}
`;

const Button = styled.button`
  width: 100%;
  ${(props) =>
    props.isDesktop
        ? css`
          padding: 1.1111vw;
          font-size: ${props.theme.Web_fontSizes.Body10};
          font-weight: ${props.theme.fontWeights.Body10};
          line-height: ${props.theme.LineHeight.Body10};
          border-radius: 1.1111vw;
          margin-bottom: 4.4444vw;
        `
        : css`
          padding: 4.2667vw;
          font-size: ${props.theme.App_fontSizes.Body10};
          font-weight: ${props.theme.fontWeights.Body10};
          line-height: ${props.theme.AppLineHeight.Body10};
          border-radius: 4.2667vw;
          margin-bottom: 18.1333vw;
        `}
  background-color: ${(props) => (props.primary ? "#6b47dc" : "#d1d5d9")};
  color: #ffffff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#5a3fc7" : "#b0b3b7")};
  }
`;

const Body21 = styled.div`
  ${(props) =>
    props.isDesktop
        ? css`
          font-size: ${props.theme.Web_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.LineHeight.Body20};
        `
        : css`
          font-size: ${props.theme.App_fontSizes.Body21};
          font-weight: ${props.theme.fontWeights.Body21};
          line-height: ${props.theme.AppLineHeight.Body21};
        `}
  color: ${(props) => props.color || props.theme.colors.navy};
  font-family: "Pretendard";

  span {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const AmountContainer = styled.div`
  width: 100%;
  ${(props) =>
    props.isDesktop
        ? css`
          padding: 32px;
          border-radius: 1.1111vw;
          width: 93%;
        `
        : css`
          padding: 4.2667vw;
          margin-bottom: 2.6667vw;
          border-radius: 4.2667vw;
        `}
  background: var(--Light-White10, #f8f8fa);
`;

const ProductForm = () => {
    const [subscriptionPeriod, setSubscriptionPeriod] = useState("1년");
    const [parametersId, SetParametersId] = useState("");
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [userInfo, setUserInfo] = useState({
        username: "",
        deceasedName: "",
        email: "",
        addr: "",
        phone: "",
        password: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const parametersIdRef = useRef(parametersId);


    const parameter = useParams();

    useEffect(() => {
        SetParametersId(parameter);
        parametersIdRef.current = parameter; // useRef를 사용하여 최신 parameter 값 설정
        console.log("parametersId : ", parameter);
    }, [parameter]);

    const handleToggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const { username, deceasedName, email, phone } = userInfo;
        return username && deceasedName && email && phone;
    };


    const handlePaymentClick = async () => {
        if (!validateForm()) {
            alert("모든 필수 정보를 입력해 주세요.");
            return;
        }
        if (!isChecked.every(Boolean)) {
            alert("모든 필수 항목에 동의해 주세요.");
            return;
        }
        console.log("parametersId : ", parametersIdRef.current.id); // useRef를 통해 최신 값 참조

        handleSubscriptionPayment(
            "추모서비스 구독(1년)",
            userInfo,
            isChecked,
            parametersIdRef.current.id, // useRef를 통해 최신 값 사용
            880000
        );
    };

    const checkboxItems = [
        {
            label: "개인정보 수집 · 이용 동의",
            link: "https://maroon-poinsettia-fa9.notion.site/64e9a9fb3142461399494ce2f02ce690",
        },
        { label: "결제대행 서비스 약관 동의", link: "https://maroon-poinsettia-fa9.notion.site/faf712efa45e44d0b85465c1844dd100?pvs=4" },
        { label: "이용 약관 동의", link: "https://maroon-poinsettia-fa9.notion.site/9cfb22c5e5694a8a8426e0c42294b009?pvs=4" },
    ];

    const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Title isDesktop={isDesktop}>소울링크 AI 추모서비스 신청하기</Title>
                <Body30 isDesktop={isDesktop}>신청자 성함</Body30>
                <Input
                    name="username"
                    placeholder="신청자분의 본명을 입력해주세요"
                    value={userInfo.username}
                    onChange={handleInputChange}
                    isDesktop={isDesktop}
                />
                <Body30 isDesktop={isDesktop}>고인 성함</Body30>
                <Input
                    name="deceasedName"
                    placeholder="고인분의 본명을 입력해주세요"
                    value={userInfo.deceasedName}
                    onChange={handleInputChange}
                    isDesktop={isDesktop}
                />
                <Body30 isDesktop={isDesktop}>휴대폰 번호</Body30>
                <Input
                    name="phone"
                    placeholder="‘-’ 제외하고 숫자만 입력해주세요"
                    value={userInfo.phone}
                    onChange={handleInputChange}
                    isDesktop={isDesktop}
                />
                <Body30 isDesktop={isDesktop}>이메일</Body30>
                <Input
                    name="email"
                    placeholder="결제 내역 수신용 이메일 주소를 입력해 주세요"
                    value={userInfo.email}
                    onChange={handleInputChange}
                    isDesktop={isDesktop}
                />
                <Body30 isDesktop={isDesktop}>비밀번호</Body30>
                <InputContainer isDesktop={isDesktop}>
                    <Input
                        name="password"
                        placeholder="결제 내역 확인용 비밀번호(숫자 4자리)"
                        value={userInfo.password}
                        onChange={handleInputChange}
                        isDesktop={isDesktop}
                        type={showPassword ? "text" : "password"}
                        maxLength={4}
                        pattern="\d*"
                    />
                    <EyeIcon
                        src={showPassword ? visibleIcon : inVisibleIcon}
                        alt="Toggle password visibility"
                        onClick={handleToggleShowPassword}
                        isDesktop={isDesktop}
                    />
                </InputContainer>
                <Body30 isDesktop={isDesktop}>이용 기간</Body30>
                <Select
                    value={subscriptionPeriod}
                    onChange={(e) => setSubscriptionPeriod(e.target.value)}
                    isDesktop={isDesktop}
                >
                    <option value="">이용 기간 선택</option>
                    <option value="1년">1년</option>
                </Select>
                <Body30 isDesktop={isDesktop}>결제 금액</Body30>
                <PaymentDetails subscriptionPeriod={subscriptionPeriod} />
                <Body30 isDesktop={isDesktop}>상품 구입 안내</Body30>
                <AmountContainer isDesktop={isDesktop}>
                    <Body30 top={0.00001} isDesktop={isDesktop}>
                        1년 후 연장 시 이용료
                    </Body30>
                    <Body21 isDesktop={isDesktop}>
                        연장 희망 시, 연 66,000원 결제를 통해 1년간 10인 사용이 가능합니다.
                    </Body21>
                    <Body30 top={3.2} isDesktop={isDesktop}>
                        취소 및 환불
                    </Body30>
                    <Body21 isDesktop={isDesktop}>
                        취소 및 환불을 원하실 경우 카카오톡 '소울링크' 채널 및 soullink@kakao.com 메일로 문의 부탁드립니다.
                    </Body21>
                </AmountContainer>
                <TermsAndConditions
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    checkboxItems={checkboxItems}
                />
                <Button
                    primary={validateForm() && isChecked.every(Boolean)}
                    onClick={handlePaymentClick}
                    isDesktop={isDesktop}
                >
                    신청하기
                </Button>
            </Container>
        </ThemeProvider>
    );
};

export default ProductForm;
