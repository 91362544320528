import React, { useRef } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../Style/theme";
import AfterPayText from "./Atoms/AfterSubscribeText";
import { useMediaQuery } from "react-responsive";
import AppProductTopButton from "./Atoms/ProductButton";
import ProductForm from "./Organisms/ProductForm";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isDesktop
        ? css`
            max-width: 64%;
        `
        : css`
            max-width: 768px;
        `}
`;

const AfterPayImg = styled.img`
  width: 90%;
  height: auto;
  ${(props) =>
    props.isDesktop
        ? css`
          margin-bottom: 100px;
        `
        : css`
          margin-bottom: 16vw;
        `}
`;

const AppProduct = () => {
    const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

    const subscribeFormRef = useRef(null);

    const scrollToSubscribeForm = () => {
        subscribeFormRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <ThemeProvider theme={theme}>
            <PageContainer isDesktop={isDesktop}>
                <AppProductTopButton
                    isDesktop={isDesktop}
                    onButtonClick={scrollToSubscribeForm}
                />
                <div
                    ref={subscribeFormRef}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <ProductForm ref={subscribeFormRef} />
                </div>
                <AfterPayText />
                <AfterPayImg
                    src={require("../../../assets/AfterPayApp.png")}
                    isDesktop={isDesktop}
                />
            </PageContainer>
        </ThemeProvider>
    );
};

export default AppProduct;
