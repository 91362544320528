import React from "react";
import styled, { css } from "styled-components";
import { useMediaQuery } from "react-responsive";

const AmountContainer = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 32px;
          border-radius: 1.1111vw;
          width: 92.5%;
        `
      : css`
          padding: 4.2667vw;
          margin-bottom: 2.6667vw;
          border-radius: 4.2667vw;
          width: 100%;
        `}
  background: var(--Light-White10, #f8f8fa);
`;

const AmountItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body40};
          font-weight: ${props.theme.fontWeights.Body40};
          line-height: ${props.theme.LineHeight.Body40};
          margin-top: 1.1111vw;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body40};
          font-weight: ${props.theme.fontWeights.Body40};
          line-height: ${props.theme.AppLineHeight.Body40};
          margin-top: 1.6vw;
        `}
  color: ${(props) => props.theme.colors.black02};
`;

const HR = styled.hr`
  width: 100%;
  background: var(--Light-Gray00, #f2f2f8);
  ${(props) =>
    props.isDesktop
      ? css`
          height: 0.2778vw;
          margin-top: 1.1111vw;
        `
      : css`
          height: 0.5333vw;
          margin-top: 2.1333vw;
        `}
  border: none;
`;

const Header6 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.LineHeight.Header6};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.AppLineHeight.Header6};
        `}
  color: ${(props) => props.theme.colors.black00};
  font-family: "Pretendard";
`;

const Body20 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.LineHeight.Body20};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.AppLineHeight.Body20};
        `}
  color: ${(props) => props.color || props.theme.colors.black01};
  font-family: "Pretendard";
`;

const PaymentDetails = ({ subscriptionPeriod }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  return (
    <AmountContainer isDesktop={isDesktop}>
      <AmountItem isDesktop={isDesktop}>
        <span>초기 구축 비용</span>
        <Body20 isDesktop={isDesktop}>
          {subscriptionPeriod === "1년" ? "1,600,000원" : "-"}
        </Body20>
      </AmountItem>
      <AmountItem isDesktop={isDesktop}>
        <span>할인 적용</span>
        {subscriptionPeriod === "1년" ? (
          <Body20 color="#9D5FED" isDesktop={isDesktop}>
            - 800,000원
          </Body20>
        ) : (
          <Body20 isDesktop={isDesktop}>-</Body20>
        )}
      </AmountItem>
      <HR isDesktop={isDesktop} />
      <AmountItem isDesktop={isDesktop}>
        <span>부가세포함(10%)</span>
        <Body20 isDesktop={isDesktop}>
          {subscriptionPeriod === "1년" ? "80,000원" : "-"}
        </Body20>
      </AmountItem>
      <HR isDesktop={isDesktop} />
      <AmountItem isDesktop={isDesktop}>
        <span>총 결제 금액</span>
        <Header6 isDesktop={isDesktop}>
          {subscriptionPeriod === "1년" ? "880,000원" : "-"}
        </Header6>
      </AmountItem>
    </AmountContainer>
  );
};

export default PaymentDetails;
